import React, { useState } from 'react';
import { collection, addDoc } from "firebase/firestore";
import { db } from "../Firebase/firebase";
import { Link } from 'react-router-dom';

export default function FormPage() {
    const [formData, setFormData] = useState({
        reason: '',
        message: '',
        name: '',
        gender: '',
        age: '',
        state: '',
        mobile: '',
        whatsapp: '',
        timestamp: new Date()
    });
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            await addDoc(collection(db, "formdata"), formData);
            setSubmitted(true);
            // Reset form
            setFormData({
                reason: '',
                message: '',
                name: '',
                state: '',
                mobile: '',
                whatsapp: '',
                timestamp: new Date()
            });
        } catch (error) {
            console.error("Error submitting form: ", error);
            alert("Error submitting form. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    if (submitted) {
        return (
            <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-gray-900 to-black text-gray-200 p-4">
                <div className="bg-gray-800/50 backdrop-blur-sm p-8 rounded-xl shadow-xl text-center">
                    <h2 className="text-2xl text-amber-300 font-bold mb-4">फॉर्म सफलतापूर्वक जमा कर दिया गया है!</h2>
                    <p className="text-gray-300 mb-6">हम जल्द ही आपसे संपर्क करेंगे</p>
                    <Link to="/">
                        <button className="bg-yellow-400 text-black font-bold py-2 px-4 rounded">
                            Go Home
                        </button>
                    </Link>
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col items-center bg-gradient-to-b from-gray-900 to-black min-h-screen text-gray-200 p-4">
            <div className="text-center mb-12 mt-8">
                <h1 className="text-3xl text-amber-300 font-bold mb-4">मुख्य संपर्क केंद्र मे आपका स्वागत है 🙏</h1>
                <p className="text-lg text-gray-400">कृपया केवल जरूरी होने पर ही फॉर्म भरे फालतू बातों के लिए भरे गए फॉर्म का जवाब कभी भी नही दिया जाएगा ।</p>
            </div>

            <div className="w-full max-w-lg bg-gray-800/50 backdrop-blur-sm p-8 rounded-xl shadow-xl">
                <form className="space-y-6" onSubmit={handleSubmit}>
                    {/* Radio Group */}
                    <div className="space-y-4">
                        <label className="block text-amber-300 text-lg font-medium">
                            कृपया संपर्क करने का सही कारण चुने *
                        </label>
                        <div className="space-y-2 pl-4">
                            {[
                                "ऑनलाइन गेम खेलने के लिए",
                                "वेबसाईट पर प्रचार डलवाने के लिए",
                                "वेबसाईट पर रिजल्ट डलवाने के लिए",
                                "कंपनी मे ख��ईवाल / एजेंट बनने के लिए",
                                "कंपनी मे जॉब करने के लिए",
                                "कुछ विशेष बात करने के लिए"
                            ].map((option, index) => (
                                <label key={index} className="flex items-center space-x-3 text-gray-300 hover:text-gray-100 transition-colors">
                                    <input 
                                        type="radio" 
                                        name="reason" 
                                        value={option}
                                        checked={formData.reason === option}
                                        onChange={handleInputChange}
                                        className="form-radio text-amber-500 focus:ring-amber-500" 
                                        required
                                    />
                                    <span>{option}</span>
                                </label>
                            ))}
                        </div>
                    </div>

                    <div className="space-y-4">
                        <div>
                            <label className="block text-amber-300 text-lg font-medium mb-2">
                                कृपया कुछ शब्दों मे अपनी बात कहें
                            </label>
                            <textarea 
                                name="message"
                                value={formData.message}
                                onChange={handleInputChange}
                                className="w-full px-4 py-3 bg-gray-700/50 border border-gray-600 rounded-lg focus:ring-2 focus:ring-amber-500 focus:border-transparent text-gray-200 placeholder-gray-400"
                                rows="4"
                            ></textarea>
                        </div>

                        <div>
                            <label className="block text-amber-300 text-lg font-medium mb-2">
                                आपका नाम?
                            </label>
                            <input 
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                className="w-full px-4 py-3 bg-gray-700/50 border border-gray-600 rounded-lg focus:ring-2 focus:ring-amber-500 focus:border-transparent text-gray-200"
                                required
                            />
                        </div>


                        {/* Dropdowns */}
                        <div className="space-y-4">

                            {/* State Dropdown */}
                            <div>
                                <label className="block text-amber-300 text-lg font-medium mb-2">
                                    आप किस राज्य में रहते है? *
                                </label>
                                <select 
                                    name="state"
                                    value={formData.state}
                                    onChange={handleInputChange}
                                    className="w-full px-4 py-3 bg-gray-700/50 border border-gray-600 rounded-lg focus:ring-2 focus:ring-amber-500 focus:border-transparent text-gray-200"
                                    required
                                >
                                    <option>Choose</option>
                                    <option>दिल्ली</option>
                                    <option>उत्तर प्रदेश</option>
                                    <option>राजस्थान</option>
                                    <option>हरियाणा</option>
                                    <option>मध्य प्रदेश</option>
                                    <option>पंजाब</option>
                                    <option>चंडीगढ़</option>
                                    <option>उत्तराखंड</option>
                                    <option>हिमाचल प्रदेश</option>
                                    <option>जम्मू और कश्मीर</option>
                                    <option>छत्तीसगढ़</option>
                                    <option>बिहार</option>
                                    <option>झारखंड</option>
                                    <option>गुजरात</option>
                                    <option>महाराष्ट्र</option>
                                    <option>गोवा</option>
                                    <option>पश्चिम बंगाल</option>
                                    <option>असम</option>
                                    <option>मेघालय</option>
                                    <option>आंध्र प्रदेश</option>
                                    <option>तमिलनाडु</option>
                                    <option>कर्नाटक</option>
                                    <option>ओडिशा</option>
                                    <option>केरल</option>
                                    <option>त्रिपुरा</option>
                                    <option>मणिपुर</option>
                                    <option>नगालैंड</option>
                                    <option>अरुणाचल प्रदेश</option>
                                    <option>पुडुचेरी</option>
                                    <option>मिजोरम</option>
                                    <option>सिक्किम</option>
                                    <option>अंडमान व नोकोबार द्वीप समूह</option>
                                    <option>दादरा और नगर हवेली</option>
                                    <option>दमन और दीव</option>
                                    <option>लक्षद्वीप</option>
                                    <option>लद्दाख</option>
                                </select>
                            </div>
                        </div>

                        {/* Phone Numbers */}
                        <div className="space-y-4">
                          <div>
                                <label className="block text-amber-300 text-lg font-medium mb-2">
                                    आपका whatsapp नंबर? *
                                </label>
                                <input 
                                    type="tel"
                                    name="whatsapp"
                                    value={formData.whatsapp}
                                    onChange={handleInputChange}
                                    className="w-full px-4 py-3 bg-gray-700/50 border border-gray-600 rounded-lg focus:ring-2 focus:ring-amber-500 focus:border-transparent text-gray-200"
                                />
                            </div>
                        </div>
                    </div>

                    {/* Submit Button */}
                    <div className="pt-6">
                        <button 
                            type="submit"
                            disabled={loading}
                            className="w-full bg-gradient-to-r from-amber-500 to-amber-600 text-white font-medium py-3 px-4 rounded-lg hover:from-amber-600 hover:to-amber-700 transition-all duration-200 focus:ring-2 focus:ring-amber-500 focus:ring-offset-2 focus:ring-offset-gray-900 disabled:opacity-50"
                        >
                            {loading ? 'Submitting...' : 'Submit'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}