import {BrowserRouter, Route, Routes} from "react-router-dom";
import MainPage from "./pages/mainPage";
import FullPattiChart from "./pages/fullChartPatti";
import SchemePage from "./pages/schemePage";
import FormPage from "./pages/formPage";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <>
                    <Route path="/" element={<MainPage />} />
                    <Route
                        path="/chart/:drawName/:jodi/:pattern"
                        element={<FullPattiChart />}
                    />
                    <Route path="/scheme" element={<SchemePage/>}/>
                    <Route path="/form" element={<FormPage />} />
                </>
            </Routes>
        </BrowserRouter>
    );
}


export default App;


